<!-- 题库管理首页 -->
<template>
  <div>
    <div style="display: flex;justify-content: space-between;padding: 24px;">
      <div style="display: flex;align-items: center;">
        <div style="font-size: 26px; font-weight: bold">课程分类</div>
        <el-select v-model="brandId" placeholder="品牌" clearable class="cinput ac" @change='list'>
          <el-option v-for="item in brandList" :key="item.id" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
      </div>

      <div>
        <el-button type="primary" style="height: 34px; line-height: 10px;background: #00A78E;" class="cinput"
          @click='add'>+新增分类</el-button>
        <!-- <input @change="exportEnter" type="file" ref="inputDom">导入数据</input> -->
      </div>

    </div>
    <div class="main">
      <el-table :header-cell-style="{ background: '#F7F7F7' }" :data="tableData" style="width: 100%; min-height: 600px"
        @selection-change="handleSelectionChange">
        <!-- <el-table-column
      type="selection"
      align='center'
      width="55"/> -->
        <!-- <el-table-column type="index" width="50"> </el-table-column> -->
        <el-table-column prop="record.name" label="分类名称" align="center">
        </el-table-column>
        <el-table-column prop="homeItem.id" label="快捷入口" align="center">
          <template slot-scope="scope">
            <div>
              <el-link v-if="scope.row.homeItem && scope.row.homeItem.isValidity == 1" type="success"
                :underline="true">开启</el-link>
              <el-link v-else disabled :underline="false">关闭</el-link>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="record.weight" label="权重" align="center">
        </el-table-column>
        <el-table-column prop="record.name" label="课程数量" align="center">
        </el-table-column>
        <!-- <el-table-column
         prop="des" align="center" label="分类详情" :show-overflow-tooltip="true" >
          <template slot-scope="scope">
            <div>
              {{scope.row.type==1?'小程序':'课程包'}}
            </div>
          </template>
        </el-table-column> -->
        <!-- <el-table-column prop="startTime" align="center" label="生效状态" :show-overflow-tooltip="true" >
          <template slot-scope="scope">
            <div> 
              {{date>scope.row.gettime?'已开始':'未开始'}}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="ctime" align="center" label="创建时间" :show-overflow-tooltip="true" >
        </el-table-column> -->

        <el-table-column prop="address" label="操作" align="center">
          <template slot-scope="scope">
            <div style="display: flex; justify-content: center;align-items: center;">
              <el-link type="primary" :underline="false" @click='children(scope.row)'>查看课程</el-link>
              <img src="../../images/edit2.png" alt="" style="width: 24px;height: 24px;margin: 0 20px;"
                @click='edit(scope.row)' />
              <img src="../../images/dell2.png" alt="" style="width: 24px;height: 24px;" @click="dele(scope.row)" />
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div style="
          display: flex;
          align-items: center;
          justify-content: flex-end;
        ">
        <!-- <el-button type="primary" @click='deleall' :disabled='isdisabled'>删除选中</el-button> -->
        <!-- <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageIndex"
          :page-sizes="[10, 15, 20]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination> -->
      </div>
    </div>

    <el-dialog title="添加分类" :visible.sync="dialogVisible" width="30%">
      <div style="display: flex;flex-direction: column;color: #7E8887;">
        <div class="grid-content bg-purple" style="display: flex;align-items:center;">
          <div style="flex-shrink: 0;color: #7E8887;" class="ft">分类名称：</div>
          <el-input v-model="cateGoryName" placeholder="请输入名称" style="width: 60%;"></el-input>
        </div>

        <div class="grid-content bg-purple" style="display: flex;align-items:center;margin: 20px 0 ;">
          <div style="flex-shrink: 0;color: #7E8887;" class="ft">分类品牌：</div>
          <el-select v-model="brandId" placeholder="品牌" clearable class="cinput ac" style="width: 60%;"
            :disabled="!isAdd">
            <el-option v-for="item in brandList" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>

        </div>

        <div class="grid-content bg-purple" style="display: flex;align-items: center;">
          <div style="flex-shrink: 0;color: #7E8887;" class="ft">权重设置：</div>
          <el-input v-model="weight" placeholder="权重数字越大，越靠前" style="width:60%"
            oninput="value=value.replace(/[^0-9]/g,'')"></el-input>
        </div>
        <div class="grid-content bg-purple" style="display: flex;align-items: center;margin-top: 20px;">
          <div style="flex-shrink: 0;color: #7E8887;" class="ft">首页快捷入口：</div>
          <el-switch v-model="isValidity" active-color="#13ce66">
          </el-switch>
        </div>
        <div class="grid-content bg-purple" style="display: flex;margin-top: 20px;" v-if="isValidity">
          <div style="flex-shrink: 0;color: #7E8887;" class="ft">快捷入口图标：</div>
          <img :src="picUri" alt="" style="width: 104px;height: 104px;" v-if="picUri">
          <el-link type="primary" :underline="true" @click="picUri = ''" v-if="picUri">删除</el-link>
          <input type="file" @change="uploadimg2" id='file2' accept='image/*' ref='fileimg' style="color:#fff"
            v-if='!picUri' />
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addquestionnaire" style="background: #00A78E;">添加分类</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
var COS = require('cos-js-sdk-v5');
var cos = new COS({
  getAuthorization: function (options, callback) {
    getCosCredit().then(result => {
      var data = result.data.data;
      var credentials = data && data.credentials;
      if (!data || !credentials) return console.error('credentials invalid');
      sessionStorage.setItem('bucket', data.bucket)
      callback({
        TmpSecretId: credentials.tmpSecretId,
        TmpSecretKey: credentials.tmpSecretKey,
        XCosSecurityToken: credentials.sessionToken,
        // 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
        StartTime: data.startTime, // 时间戳，单位秒，如：1580000000
        ExpiredTime: data.expiredTime, // 时间戳，单位秒，如：1580000900
      });
    })
  }
});
import { read, utils } from "xlsx"
import { questiontypeList, qsList, levelList, qssaveOrUpdate, PaperList, delePaper, goodsClassList, deleGoodsClass, courseClassList, courseDeleteAll, addCourseClass, updateCourseClass, getCosCredit } from "../../apis/index";
export default {
  data() {
    return {
      id: '',
      id2: '',
      cateGoryName: '',//分类名称
      picUri: '',
      isAdd: true,
      isValidity: false,//是否首页快捷入口
      weight: 0,
      brandList: [],
      brandId: '',
      name: '',
      dialogVisible: false,
      brandList: [],
      brandId: 1,
      date: '',
      timeState: '',
      timeStateList: [
        {
          id: 1,
          name: '未生效'
        },
        {
          id: 2,
          name: '已生效'
        },
        {
          id: 3,
          name: '已过期'
        },
      ],
      typeList: [
        {
          id: 1,
          name: '小程序'
        }, {
          id: 2,
          name: '课程包'
        }
      ],
      type: '',//问卷类型
      pageIndex: 1,
      pageSize: 10,
      total: 0,
      qslist: [],
      levelLists: [],
      value: "",
      isshow: false,
      isshow2: true,
      isdisabled: true,
      currentPage: 1,
      radio: "1",
      input: "",
      tableData: [

      ],
    };
  },
  watch: {
    input(newdata, olddata) {
      if (newdata == '') {
        this.isshow = false
        this.input = ''
        this.list()
      } else {
        this.isshow = true
      }
    },
    radio(newdata, olddata) {
      this.list()
    },
    value() {
      this.list()
    },
  },
  created() {
    this.brandList = this.$store.state.brandList
    // let date=new Date().toLocaleString( )
    // let date1=date.replace(/\//g,'-')
    // this.date=new Date(date1).getTime()
    // this.questype()
    this.list()
    // this.leave()
  },
  //   beforeRouteLeave(to, from, next) {
  //   if(to.path=='/exercisesAdd'){//添加页，就不缓存
  //     this.$route.meta.keepAlive=false
  //     setTimeout(() => {
  //       next()
  //     }, 100);
  //   }else{//详情页缓存
  //    this.$route.meta.keepAlive=true
  //     setTimeout(() => {
  //       next()
  //     }, 100);
  //   }
  //   // 导航离开该组件的对应路由时调用
  //   // 可以访问组件实例 `this`
  // },
  filters: {
    filtersDate(date) {
      return new Date(date).getTime()
    },
  },
  methods: {
    exportEnter(e) {
      const file = e.target.files[0];
      const fileReader = new FileReader();
      fileReader.onload = ev => {
        try {
          const data = ev.target.result;
          const workbook = read(data, { type: "binary" });
          const params = [];
          // 取对应表生成json表格内容
          workbook.SheetNames.forEach(item => {
            // params.push({
            //   name: item,
            //   dataList: utils.sheet_to_json(workbook.Sheets[item])
            // });
            // tableData.push(...utils.sheet_to_json(workbook.Sheets[item]));
            console.log(utils.sheet_to_json(workbook.Sheets[item]))
            inputDom.value.value = ''
          });
          // 该算法仅针对表头无合并的情况
          if (tableData.length > 0) {
            // 获取excel中第一个表格数据tableData[0][0]，并且将表头提取出来
            for (const key in tableData[0][0]) {
              tableHead.push(key);
            }
          }
          return params;
          // 重写数据
        } catch (e) {
          console.log("error:" + e);
          return false;
        }
      };
      fileReader.readAsBinaryString(file);
    },
     uploadimg2(e) {//上传图片
      if (this.$refs['fileimg'].files[0]) {
        // let filename = this.$refs['fileimg'].files[0].name
        let selectFileobj = this.$refs['fileimg'].files[0]
        this.upload2(selectFileobj)
      }
    },
    upload2(selectFileobj) {
      let that = this
      let name = selectFileobj.name
      let filename = 'content/resource/newManage/image/' +
        name.slice(0, name.length - 4) +
        new Date().getTime() +
        name.slice(-4);
      cos.options.getAuthorization('', function (obj) {
        cos.putObject({
          Bucket: sessionStorage.getItem('bucket'), /* 必须 */
          Region: 'ap-nanjing',     /* 存储桶所在地域，必须字段 */
          Key: filename,              /* 必须 */
          StorageClass: 'STANDARD',
          Body: selectFileobj, // 上传文件对象
          onProgress: function (progressData) {
            console.log(progressData)
          }
        }, function (err, data) {
          console.log(err || data);
          if (data) {
            // let url='https://'+data.Location
            let picUri = data.Location.replace(/content-1308336073.cos.ap-nanjing.myqcloud.com/g, 'https://sihuankangyuan.cn')
            that.picUri = picUri
          }
        });
      })

    },
    add() {//新增分类
      this.dialogVisible = true
      this.isAdd = true
      this.cateGoryName = ''
      this.weight = 0
      this.isValidity = false
      this.id = ''
      this.picUri = ''
    },
    async edit(row) {//编辑分类
      this.dialogVisible = true
      this.isAdd = false
      this.cateGoryName = row.record.name,
        this.weight = row.record.weight
      this.id = row.record.id
      if (row.homeItem) {
        this.id2 = row.homeItem.id
        this.picUri = row.homeItem.picUri
        this.isValidity = row.homeItem.isValidity == 1 ? true : false
      } else {
        this.id2 = ''
        this.picUri = ''
        this.isValidity = false
      }

    },
    async addquestionnaire() {//添加分类

      if (!this.isAdd) {//更新
        const params = {
          brandId: this.brandId,
          name: this.cateGoryName,
          weight: this.weight,
          homeItem: {
            picUri: this.picUri,
            isValidity: this.isValidity ? 1 : 0,
            id: this.id2
          },
          id: this.id
        }
        const { data } = await updateCourseClass(params)
        if (data.code == 200) {
          this.dialogVisible = false
          this.$message.success(data.msg);
          this.list()
        } else {
          this.$message.error(data.msg);
        }
      } else {//新增
        const params = {
          brandId: this.brandId,
          name: this.cateGoryName,
          weight: this.weight,
          homeItem: {
            picUri: this.picUri,
            isValidity: this.isValidity ? 1 : 0
          },
        }
        const { data } = await addCourseClass(params)
        if (data.code == 200) {
          this.dialogVisible = false
          this.$message.success(data.msg);
          this.list()
        } else {
          this.$message.error(data.msg);
        }
      }

    },
    look(item) {//查看统计
      console.log(item)
      let items = JSON.stringify(item)
      this.$router.push({ path: '/surveyPapgeDetails2', query: { items } })
    },
    async leave() {
      //获取等级列表
      const { data } = await levelList({ roleId: 3 });
      if (data.code == 200) {
        this.levelLists = data.data;
      } else {
        this.$message.error(data.msg);
      }
    },
    async list() {
      const params = {
        // pageIndex: this.pageIndex,
        // pageSize: this.pageSize,
        brandId: this.brandId
        // timeState:this.timeState,
        // type:1
      }
      const { data } = await courseClassList(params)
      if (data.code == 200) {
        //  let list=data.data.records.map(item=>{
        //    item.gettime=new Date(item.startTime).getTime()
        //    return item
        //  })
        this.tableData = data.data
        this.total = Number(data.data.length)
        console.log(data.data)
      }
    },
    async questype() {//题目类型列表
      const { data } = await questiontypeList();
      let qslist = data.data
      this.qslist = qslist;

    },

    details(row) {//查看详情
      this.$router.push({ path: '/courseedit', query: { row: JSON.stringify(row), brandId: this.brandId } })
    },
    children(row) {//查看课程
      this.$router.push({ path: '/coursePageList', query: { brandId: this.brandId, id: row.record.id } })
    },
    details2(row) {//添加试题
      this.$router.push({ path: '/courseedit', query: { row: JSON.stringify(row) } })
    },
    search() {
      console.log("我是搜索");
    },
    handleSelectionChange(val) {//选中
      console.log(val)
      if (val.length > 0) {
        this.isdisabled = false
      } else {
        this.isdisabled = true
      }
    },
    handleSizeChange(val) {
      this.pageSize = val
      console.log(`每页 ${val} 条`);
      this.list()
    },
    handleCurrentChange(val) {
      this.pageIndex = val
      console.log(`当前页: ${val}`);
      this.list()
    },
    dele(row) {
      const params = {
        id: row.record.id,
        brandId: this.brandId
      }
      this.$confirm('此操作将永久删除该项, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        courseDeleteAll(params).then(res => {
          if (res.data.code == 200) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.list()
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            });
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    deleall() {
      this.$confirm('此操作将永久删除选中项, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    }
  },
};
</script>

<style lang='less' scoped>
.main {
  // min-height: 800px;
  background: #fff;
  // margin: 19px 0;
  padding: 23px;
  border-radius: 14px;
}

// .main > div:first-child {
//   font-size: 26px;
//   // font-weight: bold;
// }
// .main > div:nth-child(2) {
//   display: flex;
//   // justify-content: space-between;
//   flex-direction: column;
//   height: 34px;
//   margin: 18px 0;
//   div {
//     display: flex;
//     img {
//       width: 25px;
//       height: 25px;
//     }
//   }
// }
/deep/.el-radio-button__inner {
  height: 34px;
  line-height: 10px;
}

.cinput /deep/.el-input__inner {
  height: 34px;
  line-height: 10px;
}

/deep/.el-button--primary {
  height: 34px;
  line-height: 10px;
}

/deep/.el-table::before {
  height: 0;
}

.ac /deep/.el-input__inner {
  border: none;
  background: #f7f7f7;
  // margin-right: 11px;
}

.red {
  color: #ff1b0b;
}
</style>
